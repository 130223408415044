.how-it-work {
    padding-top: 40px;
    background-image: url('../images/how-it-work-bg.png');
    background-repeat: no-repeat;
    background-position: -5px -70px;
    background-size: 100%;
    &__header {
        margin-bottom: 10px;
        @include tablet {
            text-align: center;
        }
        @include mobile {
            width: 60%;
            margin: 0 auto;
        }
    }
    @include small-screen {
        background-position: 60% -70px;
        background-size: 145%;
        background-repeat: no-repeat;
    }
    @include laptop {
        background-size: 180%;
        background-position: 70% -70px;
        background-repeat: no-repeat;
    }
    @include tablet {
        background-image: url('../images/icons/how-it-works-bg.svg');
        background-position: 55% 48%;
        background-repeat: no-repeat;
        background-size: 450%;
    }
    @include mobile {
        background-image: url('../images/icons/how-it-works-bg.svg');
        background-position: 25% 130%;
        background-repeat: no-repeat;
        background-size: 650%;
    }

    .how-it-work-lists-wrapper {
        display: flex;
        @include mobile {
            flex-direction: column;
        }
    }
}

.how-it-work-list {
    font-size: 18px;
    flex: 0.35;
    @include tablet {
        flex: 1;
    }
    &:first-child {
        margin-right: 50px;
        @include mobile {
            margin-right: 0;
        }
    }
    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        width: fit-content;
        width: 100%;
        &:last-child {
            margin-bottom: 0;
        }
        @include mobile {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 10px;
            }
        }
    }
    &__text {
        font-size: 18px;
        width: 60%;
        @include tablet {
            width: 100%;
        }
    }
    .icon-wrapper {
        margin-right: 20px;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        flex-shrink: 0;
        @include tablet {
            width: 100px;
            height: 100px;
        }
    }
}

.how-it-work-video {
    margin: 0 auto;
    padding-top: 90px;
    padding-bottom: 90px;
    width: 60%;
    @include tablet {
        width: 100%;
        padding-top: 75px;
        padding-bottom: 75px;
    }
    @include mobile {
        width: 100%;
        padding-top: 35px;
        padding-bottom: 35px;
    }
}
.how-it-work-main-text {
    margin-bottom: 19px;
    width: 55%;
    font-size: 20px;
    @include tablet {
        text-align: center;
        width: 100%;
    }
}
