.about {
    padding: 100px 0;
    background-image: url('../images/icons/about-bg.svg');
    background-position: left center;
    background-repeat: no-repeat;
    @include tablet {
        padding-top: 60px;
        padding-bottom: 50px;
        background-size: 50%;
    }
    @include mobile {
        padding-top: 50px;
        padding-bottom: 30px;
        background-position: left 10px top 100px;
    }
    &__header {
        text-align: center;
        margin-bottom: 10px;
        @include tablet {
            margin-bottom: 30px;
        }
    }
}
.about-info {
    display: flex;
    margin-bottom: 50px;
    @include tablet {
        flex-direction: column;
    }

    &:last-child {
        margin-bottom: 0;
    }
    &__tablet {
        display: none;
        text-align: center;
        @include tablet {
            display: block;
            margin-bottom: 10px;
        }
    }
    &__header {
        font-size: 26px;
        text-transform: uppercase;
        margin-bottom: 5px;
        font-weight: 900;
        @include mobile {
            font-size: 22px;
        }
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    &__text {
        font-size: 16px;
    }
    &__image {
        flex-shrink: 0;
    }
    .first-image {
        margin-right: 30px;
        @include mobile {
            margin-right: 0;
            margin-bottom: 30px;
        }
    }
    .second-image {
        margin-left: 30px;
        @include tablet {
            margin-left: 0;
            margin-right: 30px;
        }
        @include mobile {
            margin-bottom: 30px;
        }
    }
    .about-info-text-wrapper {
        @include tablet {
            display: none;
        }
    }
    .about-wrapper {
        display: flex;
        @include mobile {
            flex-direction: column;
        }
        &.order {
            @include tablet {
                .about-info__wrapper {
                    order: 2;
                }
                .about-info__image {
                    order: 1;
                }
            }
        }
    }
}
