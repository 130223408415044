.welcome {
    background-image: url('../images/welcome-bg-pc.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.4s;
    @include large-screen {
        background-size: cover;
        background-position-x: 74%;
    }
    @include small-screen {
        background-size: 125%;
    }
    @include laptop {
        background-size: 140%;
    }
    @include tablet {
        background-image: url('../images/icons/welcome-bg-tablet.svg');
        background-size: auto 100%;
    }
    .hidden-div {
        height: 1px;
        background: transparent;
        visibility: hidden;
    }
    &-logo {
        padding: 20px 0;
    }
    &-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        &__text {
            font-size: 20px;
            margin-bottom: 30px;
            @include small-screen {
                font-size: 18px;
            }
            @include tablet {
                font-size: 16px;
            }
        }
        &__button {
            @include blueButton;
            padding: 20px;
            text-transform: uppercase;
            font-weight: 900;
            margin-bottom: 30px;
        }
        &__license {
            font-size: 14px;
            color: #8389ac;
        }
        &__link {
            text-decoration: underline;
            color: #1c94ce;
        }
    }
    &-header {
        margin-bottom: 20px;
        font-weight: 900;
        @include mobile {
            font-size: 30px;
        }
    }
    .tablet-img {
        display: none;
        @include tablet {
            display: block;
            width: 60%;
            margin: 0 auto;
        }
        @include mobile {
            width: 95%;
        }
    }
}
.welcome-body-text__wrapper {
    width: 50%;
    @include tablet {
        padding-top: 48px;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        text-align: center;
        align-items: center;
        width: 80%;
        margin-bottom: 22px;
    }
    @include mobile {
        width: 100%;
    }
}
.hide-welcome {
    transition: 1s all;
    scale: 0;
}
.hide {
    display: none;
}
