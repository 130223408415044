@font-face {
    font-family: 'helios';
    src: url('../fonts/shrift_heliosext_bold-webfont.woff2') format('woff2'),
        url('../fonts/shrift_heliosext_bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'helios';
    src: url('../fonts/shrift_heliosext-webfont.woff2') format('woff2'),
        url('../fonts/shrift_heliosext-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'helios';
    src: url('../fonts/shrift_heliosextblack-webfont.woff2') format('woff2'),
        url('../fonts/shrift_heliosextblack-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
