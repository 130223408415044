.footer {
    padding: 50px 0;
    background-color: $footer-background;
    color: $footer-text-color;
    font-size: 15px;
    @include tablet {
        padding: 30px 0;
    }
    &-logo {
        margin-bottom: 20px;
        @include tablet {
            margin-bottom: 10px;
        }
    }
    &-logo-text {
        font-weight: 700;
        text-transform: uppercase;
        span {
            color: $main-color;
        }
        @include tablet {
            text-align: center;
        }
    }
    &-header {
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 20px;
        color: white;
    }
    &-flex {
        display: flex;
        align-items: flex-start;
        @include tablet {
            flex-direction: column;
            align-items: center;
        }
    }
    &-wrapper {
        @include tablet {
            text-align: center;
        }
        @include mobile {
            margin-bottom: 30px;
        }
        &:last-child {
            @include mobile {
                margin-bottom: 0;
            }
        }
    }
}
.logo-wrapper {
    flex: 0.7;
    @include tablet {
        flex: 1;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.lists-wrapper {
    display: flex;
    @include tablet {
        flex-direction: column;
    }
}
.footer-list {
    margin-right: 30px;
    @include tablet {
        margin-right: 0;
        margin-bottom: 20px;
    }
    &:last-child {
        @include tablet {
            margin-bottom: 0;
        }
    }
    &__item {
        margin-bottom: 20px;
        &:hover {
            color: $footer-text-hover;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.footer-info-wrapper {
    display: flex;
    justify-content: space-between;
    flex: 1;
    @include tablet {
        width: 100%;
        justify-content: space-evenly;
    }
    @include mobile {
        flex-direction: column;
    }
}
