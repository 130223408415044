.what-we-can-lists-wrapper {
    display: flex;
    @include tablet {
        justify-content: space-between;
    }
    .how-it-work-list {
        flex: 0.3;
        @include tablet {
            flex: 1;
            margin-right: 10px;
        }
        @include mobile {
            margin-right: 0;
            width: 85%;
            margin: 0 auto;
        }
    }
    @include mobile {
        flex-direction: column;
        align-items: center;
    }
}
.what-we-can {
    background-image: url('../images/icons/what-we-can-bg.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-position-y: 400%;
    @include large-screen {
        background-size: 100%;
        background-position-y: 0;
        background-position: bottom;
    }
    &-header {
        margin-bottom: 10px;
        @include tablet {
            text-align: center;
            margin: 0 auto;
            margin-bottom: 10px;
        }
    }
    @include small-screen {
        background-position-x: 50%;
    }
    @include tablet {
        background-position-y: -100%;
    }
    @include mobile {
        background-position-y: 278%;
    }
    &-wrapper {
        padding-top: 100px;
        padding-bottom: 170px;
        @include tablet {
            padding: 60px 0;
        }
        @include mobile {
            padding: 40px 0;
        }
    }
}
.other-bg {
    background-image: url('../images/what-we-can-man.png');
    background-position: right bottom -100px;
    background-repeat: no-repeat;
    background-size: auto 100%;
    @include small-screen {
        background-size: auto 90%;
    }
    @include laptop {
        background-size: auto 80%;
    }
    @include tablet {
        background-image: none;
    }
}
.under-header-text {
    font-size: 18px;
    margin-bottom: 20px;
    @include tablet {
        text-align: center;
    }
}
