.main-wrapper {
    background: url('../images/icons/main-top-bg.svg') center top no-repeat;
    @include large-screen {
        background-size: 100%;
    }
}
.main {
    scale: 1;
    padding-top: 140px;
    padding-bottom: 80px;
    transition: 0.7s;
    background: url('../images/icons/main-bottom-bg.svg') center bottom no-repeat;
    @include large-screen {
        background-size: 100%;
    }
    @include tablet {
        padding-top: 50px;
    }
    @include mobile {
        padding-bottom: 40px;
    }
    color: #fff;

    &-top-header {
        margin-bottom: 30px;
        line-height: 1;
        span {
            color: $text-color;
        }
    }
    &-top-text {
        margin-bottom: 30px;
        width: 90%;
        span {
            font-weight: 900;
            text-decoration: underline;
        }
        @include tablet {
            margin: 0 auto;
            margin-bottom: 30px;
        }
        @include tablet {
            width: 100%;
        }
    }
    &-top-button {
        @include whiteButton;
        padding: 20px 100px;
    }
    .main-top-wrapper-text {
        padding-top: 15px;
        width: 60%;
        @include tablet {
            width: 90%;
            text-align: center;
        }
        @include mobile {
            width: 100%;
        }
    }
    .main-top-wrapper-image {
        position: relative;
        @include tablet {
            width: 85%;
            margin: 0 auto;
        }
        @include mobile {
            width: 100%;
        }
    }
    &-tablet-gif {
        position: absolute;
        left: 51%;
        top: 50%;
        transform: translate(-51%, -51%);
        width: 91%;
        border-radius: 0.625rem;
    }
    .second-header {
        font-weight: 900;
        font-size: 30px;
        text-transform: uppercase;
        @include small-screen {
            font-size: 26px;
        }
        @include mobile {
            font-size: 22px;
        }
    }
    .main-text {
        margin-top: 35px;
        span {
            font-weight: 700;
            text-decoration: underline;
        }
        @include tablet {
            margin-top: 20px;
            width: 100%;
        }
    }
    .button-wrapper {
        display: flex;
        justify-content: center;
        .main-button {
            @include blueButton;
            padding: 18px;
            font-weight: 900;
            text-transform: uppercase;
        }
    }
}
.main-top-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 150px;
    .button-wrapper {
        display: flex;
        justify-content: flex-start;
        @include tablet {
            justify-content: center;
        }
    }
    @include tablet {
        flex-direction: column;
        margin: 0 auto;
        margin-bottom: 60px;
    }
    @include mobile {
        margin-bottom: 35px;
    }
}
.main-center-wrapper,
.main-bottom-wrapper {
    color: $text-color;
    display: flex;
    align-items: center;
    @include tablet {
        flex-direction: column;
        text-align: center;
        margin-bottom: 40px;
    }
    .main-center-gif {
        position: relative;
        flex: 0.6;
        margin-right: 20px;
        @include tablet {
            margin-right: 0;
            order: 2;
        }
        .admin-gif {
            position: absolute;
            top: 3%;
            left: 51%;
            width: 80%;
            transform: translate(-51%, 0);
            border-radius: 10px;
        }
    }
    .main-center-text-wrapper {
        flex: 0.4;
        @include tablet {
            margin: 0 auto;
            order: 1;
            margin-bottom: 10;
            width: 90%;
            @include mobile {
                width: 100%;
            }
        }
    }
}
.main-bottom-wrapper {
    justify-content: center;
    gap: 50px;
    @include tablet {
        flex-direction: row;
    }
    @include mobile {
        flex-direction: column;
        gap: 20px;
        margin-bottom: 25px;
    }
    .main-bottom-text-wrapper {
        flex: 0.7;
        @include tablet {
            flex: 2;
            flex-shrink: 2;
            text-align: left;
        }
        @include mobile {
            text-align: center;
        }
    }
    .main-bottom-gif {
        position: relative;
        @include tablet {
            flex: 1.5;
            flex-shrink: 1;
        }
        .admin-gif {
            position: absolute;
            top: 58%;
            left: 50%;
            width: 90%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            border-radius: 25px;
            height: 77%;
        }
    }
}
.hide-background {
    background: none;
}
.hide {
    display: none;
}
.hide-scale {
    position: absolute;
    top: -10000%;
    transition: 0.4s;
    scale: 0;
}
.header-hidden {
    position: absolute;
    top: -10000%;
}
