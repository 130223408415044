.plans {
    padding: 100px 0;
    background-image: url('../images/icons/tariff-bg.svg');
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 120%;
    @include tablet {
        padding: 50px 0;
    }
    @include mobile {
        padding: 35px 0;
    }
    &__header {
        margin-bottom: 30px;
        text-align: center;
    }
}
.plans-list {
    display: flex;
    gap: 30px;
    &__item {
        padding: 35px;
        border-radius: 25px;
        padding-bottom: 15px;
        flex: 1;
        display: flex;
        flex-direction: column;
        background: linear-gradient(
            162.51deg,
            #ebf3f8 0%,
            #eef8fd 25.69%,
            rgba(246, 251, 254, 0) 43.78%,
            rgba(255, 255, 255, 0.450217) 65.45%,
            rgba(255, 255, 255, 0) 99.73%
        );
        box-shadow: 0px 15px 40px -6px rgba(31, 80, 125, 0.3);
        backdrop-filter: blur(5px);
        background-image: url('../images/icons/tariff-vector.svg');
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: contain;
        border: 2px solid;

        border-image-source: linear-gradient(
            180deg,
            #ffffff 0%,
            rgba(221, 240, 248, 0) 21.9%,
            rgba(255, 255, 255, 0.25) 100%
        );

        .item-wrapper {
            flex: 1;
        }
        @include tablet {
            box-shadow: none;
            border: 3px solid #ececec;
        }
        @include mobile {
            margin-right: 0;
        }
        .sale-price {
            display: none;
        }
    }
    &__header {
        font-size: 27px;
        margin-bottom: 7px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 900;
        @include small-screen {
            font-size: 24px;
        }
    }
    &__price {
        margin-bottom: 30px;
        color: #1593d0;
        text-align: center;
    }
    &__svg {
        margin-right: 10px;
        flex-shrink: 0;
    }
    &__wrapper {
        display: flex;
        align-items: center;
        padding: 10px 0;
        &:first-of-type {
            border-top: 1px solid rgba(30, 38, 50, 0.1);
        }
        border-bottom: 1px solid rgba(30, 38, 50, 0.1);
        &:last-child {
            border-bottom: none;
            margin-bottom: 50px;
        }
    }
    &__wrapper.disabled {
        .plans-list__feature,
        .plans-list__svg {
            opacity: 0.25;
        }
    }
    &__button {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 15px;

        @include whiteButton;
    }
}
.start-plan {
    margin-bottom: 0;
}
.last-child-price {
    margin-bottom: 8px;
}
.checkbox-container {
    border-radius: 50px;
    background-color: white;
    display: flex;
    margin: 0 auto;
    color: white;
    margin-bottom: 20px;
    width: fit-content;
    position: relative;
    .hidden {
        display: none;
    }
    .hidden + .label {
        color: white;
    }
    .hidden:checked + .label {
        color: $main-color;
    }
    .hidden:checked + .label + label {
        color: white;
    }
    .hidden:checked ~ .checkbox-visible {
        transform: translateX(100%);
    }
    .label {
        display: block;
        color: $main-color;
        padding: 12px 8px;
        font-weight: 700;
        cursor: pointer;
        width: 190px;
        text-align: center;
        font-size: 18px;
        z-index: 1;
    }
    .checkbox-visible {
        border-radius: 52px;
        background-color: $main-color;
        position: absolute;
        left: 0;
        right: 50%;
        top: 0;
        bottom: 0;
        z-index: 0;
        transition: all 0.3s ease;
    }
}
.plans-list.half-year {
    .plans-list__item {
        .first-price {
            text-decoration: line-through;
            color: red;
            span {
                margin-left: 5px;
                text-decoration: none;
                color: #1593d0;
                display: inline-block;
            }
        }
    }
}
