//mixins
@mixin large-screen {
    @media (min-width: 1921px) {
        @content;
    }
}

@mixin small-screen {
    @media (max-width: 1366px) {
        @content;
    }
}

@mixin laptop {
    @media (max-width: 1024px) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 480px) {
        @content;
    }
}

@mixin se {
    @media (max-width: 320px) {
        @content;
    }
}

@mixin buttonHover {
    background: transparent;
    color: #fff;
    &:hover {
        background-color: #fff;
        color: $main-color;
        @content;
    }
}

@mixin whiteButton {
    text-transform: uppercase;
    border-radius: 10px;
    border: 2px solid #ffffff;
    color: white;
    background: transparent;
    transition: 0.3s;
    font-weight: 900;
    &:hover {
        background-color: #fff;
        color: $main-color;
        @content;
    }
}

@mixin blueButton {
    @content;
    background: $button-gradient;
    transition: background 0.3s ease-out;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    flex-shrink: 0;
    min-width: 296px;
    max-width: 296px;
    &:hover {
        background-position: 296px;
    }
}
//variables
$rootFontSize: 16px;

//color-variables
$footer-background: #1e2632;
$text-color: #1e2632;
$cancel-button-background: #eaf0f8;
$main-color: #1fb5fe;
$footer-text-color: #aebed5;
$footer-text-hover: #1c94ce;

$button-gradient: linear-gradient(249.08deg, $main-color 1.9%, #077db7 98.95%);
$button-hover-gradient: linear-gradient(249.08deg, #077db7 1.9%, $main-color 98.95%);

//blocks

@import './blocks/*.scss';
@import './layout/*.scss';
