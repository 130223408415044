html,
body {
    min-height: 100vh;
}
html {
    font-size: $rootFontSize;
}
body {
    line-height: 1.42;
    font-family: 'helios', Helvetica, sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    color: $text-color;
}
.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    section {
        flex: 1;
    }
}
* {
    box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}
ul {
    padding: 0;
    margin: 0;
}
ul li {
    padding: 0;
    list-style: none;
}
a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
button {
    cursor: pointer;
    border: none;
    background: transparent;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
.container {
    width: 95%;
    max-width: 1400px;
    margin: 0 auto;
}
img {
    display: block;
    max-width: 100%;
    width: 100%;
}
.section-bg {
    background-color: #f9fbfc;
}
.base-header {
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 900;
    @include tablet {
        font-size: 40px;
    }
    @include mobile {
        font-size: 30px;
    }
}
.overflow-hidden {
    overflow: hidden;
}

b,
strong {
    font-weight: 400;
}
i {
    font-style: normal;
}
