.header {
    font-size: 15px;
    transition: 0.7s;
    position: sticky;
    top: 0;
    z-index: 9999;
    background: white;
    &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    padding: 10px 0;
    &-logo {
        display: flex;
        align-items: center;
        &__telegram {
            margin-right: 10px;
        }
    }
}
.header-right-part {
    display: flex;
    align-items: flex-end;
    .header-menu {
        @include tablet {
            display: none;
        }
        display: flex;
        &__item {
            margin-right: 35px;
            padding-bottom: 13px;
            border-bottom: 2px solid transparent;
            &:hover {
                border-bottom: 2px solid $main-color;
            }
        }
    }
    .header-button {
        padding: 16px 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 900;
        @include blueButton;
        @include tablet {
            display: none;
        }
        &__icon {
            margin-right: 10px;
        }
    }
}
.header-breadcrumb {
    display: none;
    padding: 15px;
    background-color: $text-color;
    border-radius: 10px;
    cursor: pointer;
    @include tablet {
        display: block;
    }
    &__line {
        height: 2px;
        border-radius: 15px;
        width: 22px;
        margin-bottom: 7px;
        background-color: #fff;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.header-mobile-menu {
    position: absolute;
    right: 0;
    top: -2000%;
    height: 0;
    padding: 0 30px;
    width: 95%;
    background-color: $text-color;
    color: #fff;
    transition: 0.4s;
    z-index: 1;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    font-size: 16px;
    background-image: url('../images/icons/about-bg.svg');
    &__close {
        display: flex;
        position: absolute;
        top: 20px;
        right: 20px;
    }
    &__list {
        text-align: right;
    }
    &__item {
        margin-bottom: 75px;
    }
}
.header-button__icon {
    fill: white;
}
.header-mobile-menu.active {
    height: 100vh;
    padding: 100px 30px;
    top: 0;
}
.header {
    .header-right-part {
        .header-button.header-button__mobile {
            align-self: center;
            border: none;
            color: white;
            &:hover {
                color: white;
            }
            @include blueButton;
            @include tablet {
                display: flex;
            }
        }
    }
}

.header.main-header {
    background: transparent;
    color: white;
    .header-button {
        background: transparent;
        border: 2px solid #fff;

        @include buttonHover {
            .header-button__icon {
                fill: $main-color;
            }
        }
    }
    .header-menu__item {
        &:hover {
            border-bottom: 2px solid #fff;
        }
    }
}
.header.main-header.header-active-bg {
    background-color: $main-color;
}
.header.main-header {
    .header-right-part {
        .header-menu__item {
            padding-bottom: 1rem;
        }
    }
}
